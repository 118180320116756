import { createReducer, on } from '@ngrx/store';
import { Bin } from '../../shared/models/api/bin';
import { resetBin, setBin } from '../actions/bin.actions';

export interface BinState {
  bin: Bin;
}

export const initialState: BinState = {
  bin: { id: '', code: '' },
};

export const binReducer = createReducer(
  initialState,
  on(setBin, (state, { bin }) => ({
    ...state,
    bin,
  })),
  on(resetBin, () => ({
    ...initialState,
  }))
);
