import { createReducer, on } from '@ngrx/store';
import { User } from '../../shared/models/api/user';
import * as UserActions from '../actions/user.actions';

export interface UserState {
  user: User | null;
}

export const initialState: UserState = {
  user: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.loginUserSuccess, (state, { user }) => ({
    ...state,
    user,
  }))
);
