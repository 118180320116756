import { createReducer, on } from '@ngrx/store';
import { CollectionPin } from '../../shared/models/local/collection-pin';
import {
  resetCollectionPin,
  setCollectionPin,
} from '../actions/collection-pin.actions';

export interface CollectionPinState {
  collectionPin: CollectionPin;
}

export const initialState: CollectionPinState = {
  collectionPin: { pin: '' },
};

export const collectionPinReducer = createReducer(
  initialState,
  on(setCollectionPin, (state, { collectionPin }) => ({
    ...state,
    collectionPin,
  })),
  on(resetCollectionPin, () => ({
    ...initialState,
  }))
);
