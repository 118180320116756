import { createReducer, on } from '@ngrx/store';
import { PackageDetails } from '../../shared/models/api/package-details';
import {
  resetPackageDetails,
  setPackageDetails,
} from '../actions/package-details';

export interface PackageDetailsState {
  packageDetails: PackageDetails;
}

export const initialState: PackageDetailsState = {
  packageDetails: {
    handlingType: '',
    contentType: '',
    serviceType: '',
  },
};

export const packageDetailsReducer = createReducer(
  initialState,
  on(setPackageDetails, (state, { packageDetails }) => ({
    ...state,
    packageDetails,
  })),
  on(resetPackageDetails, () => ({
    ...initialState,
  }))
);
