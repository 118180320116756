import { createReducer, on } from '@ngrx/store';
import { PackageDetailOption } from '../../shared/models/api/package-detail-option';
import {
  setContentTypeOptions,
  setHandlingTypeOptions,
  setServiceTypeOptions,
  resetHandlingTypeOptions,
  resetServiceTypeOptions,
  resetContentTypeOptions,
} from '../actions/package-detail-option.actions';

export interface PackageDetailOptionState {
  handlingTypeOptions?: PackageDetailOption[];
  serviceTypesOptions?: PackageDetailOption[];
  contentTypesOptions?: PackageDetailOption[];
}

export const initialState: PackageDetailOptionState = {
  handlingTypeOptions: [],
  serviceTypesOptions: [],
  contentTypesOptions: [],
};

export const packageDetailOptionReducer = createReducer(
  initialState,
  on(setHandlingTypeOptions, (state, { handlingTypeOptions }) => ({
    ...state,
    handlingTypeOptions: handlingTypeOptions,
  })),
  on(setServiceTypeOptions, (state, { serviceTypeOptions }) => ({
    ...state,
    serviceTypesOptions: serviceTypeOptions,
  })),
  on(setContentTypeOptions, (state, { contentTypeOptions }) => ({
    ...state,
    contentTypesOptions: contentTypeOptions,
  })),
  on(resetHandlingTypeOptions, (state) => ({
    ...state,
    handlingTypeOptions: [],
  })),
  on(resetServiceTypeOptions, (state) => ({
    ...state,
    serviceTypesOptions: [],
  })),
  on(resetContentTypeOptions, (state) => ({
    ...state,
    contentTypesOptions: [],
  }))
);
