import { createReducer, on } from '@ngrx/store';
import { Consignee } from '../../shared/models/api/consignee';
import { resetConsignee, setConsignee } from '../actions/consignee.actions';

export interface ConsigneeState {
  consignee: Consignee;
}

export const initialState: ConsigneeState = {
  consignee: {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    specialInstructions: '',
    languagePreference: '',
  },
};

export const consigneeReducer = createReducer(
  initialState,
  on(setConsignee, (state, { consignee }) => ({
    ...state,
    consignee,
  })),
  on(resetConsignee, (state) => ({
    ...initialState,
  }))
);
