import { createReducer, on } from '@ngrx/store';
import { Courier } from '../../shared/models/api/courier';
import { resetCourier, setCourier } from '../actions/courier.actions';

export interface CourierState {
  courier: Courier;
}

export const initialState: CourierState = {
  courier: { no: 0, name: '', code: '' },
};

export const courierReducer = createReducer(
  initialState,
  on(setCourier, (state, { courier }) => ({
    ...state,
    courier,
  })),
  on(resetCourier, () => ({
    ...initialState,
  }))
);
