import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  finalize,
  throwError,
  from,
  switchMap,
} from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../authentication/auth.service';
import { QueryProgressService } from '../../shared/services/progress/query-progress.service';

@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
  private loading = false;

  translocoService = inject(TranslocoService);
  _snackbar = inject(MatSnackBar);
  firebaseAuth = inject(Auth);
  authService = inject(AuthService);
  queryProgressService = inject(QueryProgressService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.showLoader();
    this.queryProgressService.setLoading(true);

    const apiUrl = environment.apiUrl;

    const excludedUrls = [
      `${apiUrl}/customer-drop/system/fetch-startup-data`,
      new RegExp(
        `^${apiUrl.replace(
          /[.*+?^${}()|[\]\\]/g,
          '\\$&'
        )}/customer-drop/nodes/.+/users$`
      ),
    ];

    // Check if the request URL matches any of the excluded URLs
    const shouldAddToken = !excludedUrls.some((url) =>
      typeof url === 'string'
        ? request.url.includes(url)
        : url.test(request.url)
    );

    const isUsersEndpoint = new RegExp(
      `^${apiUrl.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&'
      )}/customer-drop/nodes/.+/users$`
    ).test(request.url);

    // If it's the users endpoint and not a GET request, force token requirement
    const needsTokenForUsersEndpoint =
      isUsersEndpoint && request.method !== 'GET';

    return from(this.getTokenAndCheckAuthentication()).pipe(
      switchMap(({ token, isAuthenticated }) => {
        if (
          token &&
          (shouldAddToken || needsTokenForUsersEndpoint) &&
          isAuthenticated
        ) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        // Extract existing parameters
        let params = request.params;
        // params = params.append('lan', this.translocoService.getActiveLang());

        // Clone the request with updated params
        request = request.clone({ params });

        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            this._snackbar.open(error.error.message, 'Close', {
              duration: 3000,
            });
            return throwError(() => error.message);
          }),
          finalize(() => {
            this.queryProgressService.setLoading(false);
            this.hideLoader();
          })
        );
      })
    );
  }

  private async getTokenAndCheckAuthentication(): Promise<{
    token: string | null;
    isAuthenticated: boolean;
  }> {
    const token = localStorage.getItem('paxi-next-jwt');
    if (!token) {
      return { token: null, isAuthenticated: false };
    }

    const isAuthenticated = await this.authService.isAuthenticated();
    if (!isAuthenticated) {
      // Token might be expired, remove it from localStorage
      localStorage.removeItem('paxi-next-jwt');
      return { token: null, isAuthenticated: false };
    }

    return { token, isAuthenticated };
  }

  showLoader() {
    this.loading = true;
  }

  hideLoader() {
    this.loading = false;
  }
}
