import {Component, OnInit, inject, isDevMode} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {environment} from '../environments/environment';
import {HomeService} from './services/repos/home/home.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatProgressSpinnerModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isLoading = true;
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  homeService = inject(HomeService);

  ngOnInit(): void {
    // if (isDevMode()) {
    //   this.isLoading = false;
    //   this.homeService.getStartUpData(environment.device.id);
    // } else {
      this.activatedRoute.queryParams.subscribe((params) => {
        const did = params['did'];

        if (did) {
          this.homeService.getStartUpData(did);
          this.isLoading = false;
        }
      });
    // }
  }
}
