import { createReducer, on } from '@ngrx/store';
import { Device } from '../../shared/models/api/device';
import { setDevice } from '../actions/device.actions';

export interface DeviceState {
  device: Device;
}

export const initialState: DeviceState = {
  device: {
    id: '',
    country: '',
    nodeIdNo: '',
    nodeCode: '',
    nodeName: '',
    platformTenantCode: '',
  },
};

export const deviceReducer = createReducer(
  initialState,
  on(setDevice, (state, { device }) => ({
    ...state,
    device,
  }))
);
