import { createReducer, on } from '@ngrx/store';
import { Parcel } from '../../shared/models/api/parcel';
import {
  resetParcel,
  setParcel,
  setDamagedParcels,
  setScannedParcels,
  resetScannedParcels,
  resetDamagedParcels,
  setMissingParcels,
  resetMissingParcels,
} from '../actions/parcel.actions';

export interface ParcelState {
  parcel?: Parcel;
  damagedParcels?: Parcel[];
  scannedParcels?: Parcel[];
  missingParcels?: Parcel[];
}

export const initialState: ParcelState = {
  parcel: { parcelIdNo: 0, parcelRef: '', binCode: '', damaged: false },
  damagedParcels: [],
  scannedParcels: [],
  missingParcels: [],
};

export const parcelReducer = createReducer(
  initialState,
  on(setParcel, (state, { parcel }) => ({
    ...state,
    parcel,
  })),
  on(resetParcel, (state) => ({
    ...state,
    parcel: { parcelIdNo: 0, parcelRef: '', binCode: '', damaged: false },
  })),
  on(setDamagedParcels, (state, { damagedParcels }) => ({
    ...state,
    damagedParcels: damagedParcels,
  })),
  on(resetDamagedParcels, (state) => ({
    ...state,
    damagedParcels: [],
  })),
  on(setScannedParcels, (state, { scannedParcels }) => ({
    ...state,
    scannedParcels: scannedParcels,
  })),
  on(resetScannedParcels, (state) => ({
    ...state,
    scannedParcels: [],
  })),
  on(setMissingParcels, (state, { missingParcels }) => ({
    ...state,
    missingParcels: missingParcels,
  })),
  on(resetMissingParcels, (state) => ({
    ...state,
    missingParcels: [],
  }))
);
