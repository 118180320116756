import { Routes } from '@angular/router';
import { authGuard } from '../app/core/guards/auth.guard';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () =>
      import('./features/home/pages/home/home.component').then(
        (m) => m.HomeComponent
      ),
  },
  {
    path: 'menu',
    loadComponent: () =>
      import('./features/menu/pages/menu/menu.component').then(
        (m) => m.MenuComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'customer',

    children: [
      {
        path: 'drop',
        loadComponent: () =>
          import(
            './features/customer-drop/pages/customer-drop/customer-drop.component'
          ).then((m) => m.CustomerDropComponent),
        canActivate: [authGuard],
      },
      {
        path: 'collect',
        loadComponent: () =>
          import(
            './features/customer-collect/pages/customer-collect/customer-collect.component'
          ).then((m) => m.CustomerCollectComponent),
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'parcel',

    children: [
      {
        path: 'putaway',
        loadComponent: () =>
          import('./features/put-away/pages/put-away/put-away.component').then(
            (m) => m.PutAwayComponent
          ),
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'courier',

    children: [
      {
        path: 'pickup',
        loadComponent: () =>
          import(
            './features/courier-pickup/pages/courier-pickup/courier-pickup.component'
          ).then((m) => m.CourierPickupComponent),
        canActivate: [authGuard],
      },
      {
        path: 'delivery',
        loadComponent: () =>
          import(
            './features/courier-delivery/pages/courier-delivery/courier-delivery.component'
          ).then((m) => m.CourierDeliveryComponent),
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'management',
    ///management/parcel-lookup
    children: [
      {
        path: 'menu',
        loadComponent: () =>
          import(
            './features/menu/components/management-menu/management-menu.component'
          ).then((m) => m.ManagementMenuComponent),
        canActivate: [authGuard],
      },

      {
        path: 'parcel-lookup',
        loadComponent: () =>
          import(
            './features/parcel-lookup/pages/parcel-lookup/parcel-lookup.component'
          ).then((m) => m.ParcelLookupComponent),
        canActivate: [authGuard],
      },

      {
        path: 'node-stock',
        loadComponent: () =>
          import('./features/node-stock/pages/node-stock.component').then(
            (m) => m.NodeStockComponent
          ),
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'alerts',

    children: [
      {
        path: 'menu',
        loadComponent: () =>
          import(
            './features/menu/components/alerts-menu/alerts-menu.component'
          ).then((m) => m.AlertsMenuComponent),
        canActivate: [authGuard],
      },
      {
        path: 'ncr',
        loadComponent: () =>
          import(
            './features/non-collection-return/pages/non-collection-return/non-collection-return.component'
          ).then((m) => m.NonCollectionReturnComponent),
        canActivate: [authGuard],
      },

      {
        path: 'ncr-process',
        loadComponent: () =>
          import(
            './features/non-collection-return/components/ncr-process/ncr-process.component'
          ).then((m) => m.NcrProcessComponent),
        canActivate: [authGuard],
      },

      {
        path: 'returns',
        loadComponent: () =>
          import(
            './features/general-returns/pages/general-returns.component'
          ).then((m) => m.GeneralReturnsComponent),
        canActivate: [authGuard],
      },

      {
        path: 'return-process',
        loadComponent: () =>
          import(
            './features/general-returns/components/return-process/return-process.component'
          ).then((m) => m.ReturnProcessComponent),
        canActivate: [authGuard],
      },

      {
        path: 'missing',
        loadComponent: () =>
          import(
            './features/missing-parcels-found/pages/missing-parcels-found.component'
          ).then((m) => m.MissingParcelsFoundComponent),
        canActivate: [authGuard],
      },
      {
        path: 'messages',
        loadComponent: () =>
          import('./features/alerts/pages/alerts/alerts.component').then(
            (m) => m.AlertsComponent
          ),
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'admin',

    children: [
      {
        path: 'menu',
        loadComponent: () =>
          import(
            './features/menu/components/admin-menu/admin-menu.component'
          ).then((m) => m.AdminMenuComponent),
        canActivate: [authGuard],
      },
      {
        path: 'device',
        loadComponent: () =>
          import(
            './features/device-info/pages/device-info/device-info.component'
          ).then((m) => m.DeviceInfoComponent),
        canActivate: [authGuard],
      },
      {
        path: 'users',
        loadComponent: () =>
          import(
            './features/user-management/pages/user-management.component'
          ).then((m) => m.UserManagementComponent),
        canActivate: [authGuard],
      },
    ],
  },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
