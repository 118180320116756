import { createReducer, on } from '@ngrx/store';
import { Consignor } from '../../shared/models/api/consignor';
import { resetConsignor, setConsignor } from '../actions/consignor.actions';

export interface ConsignorState {
  consignor: Consignor;
}

export const initialState: ConsignorState = {
  consignor: {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    specialInstructions: '',
    languagePreference: '',
    idType: '',
    idNumber: '',
  },
};

export const consignorReducer = createReducer(
  initialState,
  on(setConsignor, (state, { consignor }) => ({
    ...state,
    consignor,
  })),
  on(resetConsignor, (state) => ({
    ...initialState,
  }))
);
