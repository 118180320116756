import { createReducer, on } from '@ngrx/store';
import { Node } from '../../shared/models/api/node';
import { resetNode, setNode } from '../actions/node.actions';

export interface NodeState {
  node: Node;
}

export const initialState: NodeState = {
  node: {
    id: null,
    code: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    province: '',
    postalCode: '',
  },
};

export const nodeReducer = createReducer(
  initialState,
  on(setNode, (state, { node }) => ({
    ...state,
    node,
  })),
  on(resetNode, () => ({
    ...initialState,
  }))
);
