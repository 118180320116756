import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../authentication/auth.service';
import { from, map, catchError } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);

  return from(authService.isAuthenticated()).pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true;
      } else {
        authService.logout();

        return false;
      }
    }),
    catchError((error) => {
      console.error('Authentication check failed:', error);
      authService.logout();
      return from([false]);
    })
  );
};
