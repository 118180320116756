import { createReducer, on } from '@ngrx/store';
import { Order } from '../../shared/models/api/order';
import {
  resetRemainingOrders,
  resetScannedOrders,
  setRemainingOrders,
  setScannedOrders,
} from '../actions/order.actions';

export interface OrderState {
  remainingOrders: Order[];
  scannedOrders: Order[];
}

export const initialState: OrderState = {
  remainingOrders: [],
  scannedOrders: [],
};

export const orderReducer = createReducer(
  initialState,
  on(setRemainingOrders, (state, { orders }) => ({
    ...state,
    remainingOrders: orders,
  })),
  on(resetRemainingOrders, (state) => ({
    ...state,
    remainingOrders: [],
  })),
  on(setScannedOrders, (state, { orders }) => ({
    ...state,
    scannedOrders: orders,
  })),
  on(resetScannedOrders, (state) => ({
    ...state,
    scannedOrders: [],
  }))
);
