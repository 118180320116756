import { createReducer, on } from '@ngrx/store';
import { Message } from '../../shared/models/api/message';
import { resetMessage, setMessage } from '../actions/message.actions';

export interface MessageState {
  message: Message[];
}

export const initialState: MessageState = {
  message: [],
};

export const messageReducer = createReducer(
  initialState,
  on(setMessage, (state, { message }) => ({
    ...state,
    message,
  })),
  on(resetMessage, () => ({
    ...initialState,
  }))
);
